
import { Component, Vue, Watch } from 'vue-property-decorator';

import * as NewsLetterServices from '@/services/NewsletterServices';
import { NewsLetterPublication } from '@/types';

@Component({
  components: {
    ConfirmationDialog: () => import('@/components/dialogs/ConfirmationDialog.vue'),
    DailySubscribersChart: () => import('@/components/charts/newsletter/DailySubscribersChart.vue'),
    CumulativeSubscribersChart: () => import('@/components/charts/newsletter/CumulativeSubscribersChart.vue'),
  }
})
export default class Index extends Vue {

  subscribers = [] as any[]
  publications = [] as NewsLetterPublication[]

  dateRangeSelector = {
    fromToggle: false,
    from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString().substring(0, 10),
    toToggle: false,
    to: new Date().toISOString().substring(0, 10),
  }

  statsDateRange = {
    from: new Date(2022, 12, 12),
    to: new Date(2023, 1, 1)
  }


  @Watch('dateRangeSelector.from', { immediate: true })
  @Watch('dateRangeSelector.to')
  onDateSelect() {
    this.statsDateRange.from = new Date(this.dateRangeSelector.from);
    this.statsDateRange.to = new Date(this.dateRangeSelector.to);
  }

  dialogs = {
    newPublication: false,
    editDialog: false,
    deleteDialog: false
  }

  newPublication = {
    title: '',
    url: '',
    date: new Date().toISOString().split('T')[0]
  }

  loading = true;

  loadingDelete = false;

  selectedPublication: NewsLetterPublication | null = null;

  tab = null;
  exporting = false;

  async mounted(): Promise<void> {
    this.subscribers = await NewsLetterServices.GetSubsribers();
    console.log(this.subscribers.length);
    this.publications = await NewsLetterServices.ListPublications();
    this.loading = false;
  }

  formatDate(date: string): string {
    return new Date(date).toLocaleDateString('nl-BE');
  }

  openEditDialog(publication: NewsLetterPublication): void {
    this.selectedPublication = {
      ...publication,
      date: publication.date!.split('T')[0]

    };

    this.dialogs.editDialog = true;
  }

  openDeleteDialog(publication: NewsLetterPublication): void {
    this.selectedPublication = publication;
    this.dialogs.deleteDialog = true;
  }

  async confirmDelete() {
    this.loadingDelete = true;
    if (!this.selectedPublication) return;
    if (!this.selectedPublication.id) return;
    await NewsLetterServices.DeletePublication(this.selectedPublication.id);
    this.publications = await NewsLetterServices.ListPublications();
    this.loadingDelete = false;
    this.dialogs.deleteDialog = false;
  }

  async updatePublication(): Promise<void> {
    if (!this.selectedPublication) return;
    if (!this.selectedPublication.id) return;
    this.loading = true;
    await NewsLetterServices.UpdatePublication(this.selectedPublication);
    this.publications = await NewsLetterServices.ListPublications();
    this.loading = false;
    this.dialogs.editDialog = false;
  }

  async exportData(): Promise<void> {
    this.exporting = true;

    const data = [
      ['email', 'firstname', 'lastname'],
    ];

    this.subscribers.forEach(subscriber => {
      data.push([subscriber.email, subscriber.firstname, subscriber.lastname])
    });

    let csvContent = "data:text/csv;charset=utf-8,"
      + data.map(e => e.join(",")).join("\n");
    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);

    this.exporting = false;
  }

  async submit(): Promise<void> {
    this.loading = true;
    await NewsLetterServices.CreatePublication(this.newPublication);
    this.publications = await NewsLetterServices.ListPublications();
    this.loading = false;
    this.dialogs.newPublication = false;
  }
} 
