import api from '@/plugins/axios';
import { NewsLetterPublication, NewsletterSubscriber } from "@/types";

export const GetSubsribers = async (): Promise<NewsletterSubscriber[]> => {
  const { data } = await api.get<NewsletterSubscriber[]>('/newsletter')
  return data;
}

export const ListPublications = async (): Promise<NewsLetterPublication[]> => {
  const { data } = await api.get<NewsLetterPublication[]>('/newsletter/publications')
  return data.map(e => {
    return {
      ...e,
      date: e.createdAt
    }
  });
}

export const CreatePublication = async (publication: NewsLetterPublication): Promise<NewsLetterPublication> => {
  const { data } = await api.post<NewsLetterPublication>('/newsletter/publications', publication)
  return data;
}

export const UpdatePublication = async (publication: NewsLetterPublication): Promise<NewsLetterPublication> => {
  const { data } = await api.patch<NewsLetterPublication>(`/newsletter/publications/${publication.id}`, publication)
  return data;
}

export const DeletePublication = async (id: string): Promise<void> => {
  await api.delete<void>(`/newsletter/publications/${id}`)
}

export const GetDailySubscribers = async (start: Date, end: Date): Promise<Array<{ date: string, count: number }>> => {
  const { data } = await api.get<Array<{ date: string, count: number }>>('/newsletter/stats', {
    params: {
      start: start.toISOString(),
      end: end.toISOString(),
      type: 'daily'
    }
  })
  return data;
}

export const GetCumulativeSubscribers = async (start: Date, end: Date): Promise<Array<{ date: string, count: number }>> => {
  const { data } = await api.get<Array<{ date: string, count: number }>>('/newsletter/stats', {
    params: {
      start: start.toISOString(),
      end: end.toISOString(),
      type: 'cumulative'
    }
  })
  return data;
}